@use '../../styles/_colors.scss';

.logs {
  height: 801px;
  overflow-x: auto;
  list-style: none;
  margin: 0;
  padding: 0 2em;

  .logItem {
    display: flex;
    align-items: center;
    background-color: colors.$default;
    padding: 0.5em 0;

    &:nth-child(odd) {
      background-color: colors.$background;
    }

    .timestamp {
      font-size: 14px;
      padding: 0 1em;
    }

    .description {
      font-size: 14px;
      font-weight: 400;
      padding: 0 1em;

      &.success {
        color: colors.$success;
        font-weight: 700;
      }

      &.fail {
        color: colors.$fail;
        font-weight: 700;
      }
    }
  }
}

.details {
  padding: 2em;
  background-color: colors.$background;
  border-top: 1px solid colors.$border;
  border-radius: 0 0 5px 5px;

  .header {
    font-size: 18px;
    font-weight: 500;
    margin: 0;
  }

  .info {
    padding: 0;
    margin: 1em 1em 2em;

    li {
      font-size: 14px;
      line-height: 1.5em;
    }
  }
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.button {
  display: inline-block;
  background-color: colors.$default;
  margin: 0;
  padding: 0 1em;
  border: 1px solid colors.$border;
  border-radius: 10px;
  box-shadow: 0px 1px 2px rgba(#000000, 0.2);
  transition-property: background-color, color, box-shadow, border-color;
  transition-duration: 0.1s;
  transition-timing-function: ease-out;

  &:not(:last-of-type) {
    margin-right: 1em;
  }

  &:hover,
  &:active {
    border-color: colors.$active;
    box-shadow: 0px 1px 2px rgba(#000000, 0.2), inset 0px 1px 1px rgba(#000000, 0.1);
  }

  &:disabled {
    opacity: 0.7;

    &:hover,
    &:active {
      border-color: colors.$border;
      background-color: colors.$default;
      box-shadow: 0px 1px 2px rgba(#000000, 0.2);
    }
  }

  .buttonText {
    font-size: 14px;
    font-weight: 500;
    line-height: 34px;
    color: colors.$active;
  }

  &.primary {
    background-color: colors.$active;
    border-color: colors.$border-active;

    &:hover,
    &:active {
      background-color: colors.$border-active;
    }

    &:disabled {
      &:hover,
      &:active {
        border-color: colors.$border-active;
        background-color: colors.$active;
      }
    }

    .buttonText {
      color: colors.$default;
    }
  }
}
