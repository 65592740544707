@use '../../styles/_colors.scss';

.container {
  padding: 1em 1em 2em;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;

  .headerLogo {
    width: 12em;
    height: 3.5em;
    padding-right: 2em;
    border-right: 2px solid colors.$border;
  }

  .headerText {
    font-size: 18px;
    font-weight: 500;
    color: colors.$font-secondary;
    text-transform: uppercase;
    padding-left: 2rem;
    margin: 0;
  }
}

.content {
  display: flex;
  justify-content: center;

  .leftColumn {
    width: 28em;
    margin-right: 4em;
  }

  .rightColumn {
    width: 45em;
  }
}
