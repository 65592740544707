@use '../../styles/_colors.scss';

.domains {
  list-style: none;
  margin: 0;
  padding: 0;

  .domainItem {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    padding: 1em;
    border: 1px solid transparent;
    border-radius: 5px;
    box-shadow: 0px 1px 2px rgba(black, 0.2);

    &:last-of-type {
      margin-bottom: 0;
    }

    &.check,
    &.success,
    &.fail {
      box-shadow: 0px 1px 4px rgba(black, 0.4);
    }

    &.check {
      .icon {
        color: colors.$font;
      }
    }

    &.success {
      border-color: colors.$success;

      .icon {
        color: colors.$success;
      }
    }

    &.fail {
      border-color: colors.$fail;

      .icon {
        color: colors.$fail;
      }
    }

    .info {
      flex: 1;
    }

    .name {
      font-weight: 700;
      margin-bottom: 0.25em;
    }

    .status {
      font-size: 12px;
    }

    .icon {
      font-size: 32px;
      color: colors.$border;
    }
  }
}
