@use '_colors.scss';
@import-normalize;

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 16px;
  font-weight: 400;
  color: colors.$font;
}

body {
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  margin: 0;
}

input,
button,
textarea,
select {
  font: inherit;
}

img {
  max-width: 100%;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
