@use '../../styles/_colors.scss';

.card {
  padding: 1em 2em 2em;
  background-color: colors.$default;
  border: 1px solid colors.$border;
  border-radius: 5px;

  &.fullContent {
    padding: 0;
    padding-top: 1em;
  }

  &:not(:last-of-type) {
    margin-bottom: 1em;
  }

  .cardHeader {
    font-size: 14px;
    font-weight: 400;
    color: colors.$font;
    text-transform: uppercase;
    text-align: center;
    margin: 0;
    margin-bottom: 1rem;
  }
}
